.modal-body div[id^="react-select-"][id$="listbox"]
{
    background-color: #fff !important;
}

table div[id^="react-select-"][id$="listbox"]
{
    background-color: #fff !important;
}

.cursor-pointer
{
    cursor: pointer;
}
.checkbox-medium {
    width: 18px;
    height: 18px;
    min-width: 18px;
}
.table-bordered {
    border:solid 1px #c4c4c4;
    border-collapse: collapse;
}
.table-bordered tr td{
    border:solid 1px #c4c4c4 ;
    color: black;
    padding:1px 2px;
    border-collapse: collapse;
}
.table-bordered tr td small {
    padding: 0px;
    background: none;
}
.table-bordered tr td .form-control {
    border-width: 1px !important;
    padding:1px 2px;
    font-size: 13px;
}
.table-bordered tr td .form-select {
    border-width: 1px !important;
    padding:5px 7px;
    font-size: 13px;
}
body[data-layout="horizontal"][data-topbar="light"] .topnav {
    background-color: #207E0C;
}
.page-link.active, .active > .page-link {
    background-color: #207E0C;
}
@media screen { 
  .printable-report {
    padding: calc(55px + 24px) calc(24px* 0.5) 60px calc(24px* 0.5);    
    margin-top: 70px;
  }
.A4 {
    width: 21cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    padding: 10px 25px;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    box-sizing: none;
    font-size:11px;
  }
  .printable-content>.page-footer {
    display: none;
  }
  
  .printable-content>.page-header {
    display: none;
  }
  .printable-content>table{
    margin: 0 auto;
  }

  .A4 p
  {
    margin-bottom:auto;
  }
}

/* .page {
  page-break-after: always;
} */

@media print {
  
  body {        
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact; 
  }

  .page-header, .page-header-space {
    height: 35mm;
  }
  
  .page-footer, .page-footer-space {
    height: 49mm;  
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-right: 5mm;
  }
  
  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
  }  

  .A4 {
    width: 185mm;
    height: auto;
    font-size: 11px;
  }

  .A4 p
  {
    margin-bottom:auto;
  }

  table {
    width: 185mm;
    margin : 0 auto;
  }

  .no-print {
    display: none !important;
  }
  
  .enable-print {
    display: block;
  }

  thead {display: table-header-group;} 

  tfoot {display: table-footer-group;}
  
  .avoid-page-break {
    page-break-inside: avoid;
  }
  
}
.table-bordered > :not(caption) > * {
  border-width: 0;
}





  
  


